import clsx from 'clsx'
import useTranslation from 'next-translate/useTranslation'
import {useInView} from 'react-intersection-observer'
import LoadableImage from '../../../components/atoms/loadable-image'
import defaultImageLoader from '../../../lib/default-image-loader'
import lineGraphics from '../../../public/assets/line.svg'
import {HelpStep} from '../../../types'
import Box from '../../atoms/box'
import Container from '../../atoms/container'
import Heading from '../../atoms/heading'
import LandingHelpStep from '../../molecules/landing-help-step/landing-help-step'

export interface HowCanWeHelpSectionProps {
  items: Array<HelpStep>
}

function HowCanWeHelpSection({items}: HowCanWeHelpSectionProps) {
  const {t} = useTranslation('home')
  const [step1, step2, step3, step3a, step3b, step4, step5] = items
  const {inView, ref} = useInView({triggerOnce: true})

  const MobileVerticalBar = () => (
    <Box className="flex justify-center flex-col my-1 md:hidden">
      <Box className="h-7 w-0.5 bg-gradient-to-b from-transparent via-primary to-primary-light m-auto" />
      <Box className="h-3 w-3 rounded-full border-2 border-primary-light m-auto" />
    </Box>
  )

  return (
    <Container
      component="section"
      className={clsx(
        'min-w-full',
        inView &&
          'motion-safe:animate-fade-in motion-reduce:opacity-100 motion-reduce:scale-110',
      )}
      ref={ref}
    >
      <Heading
        variant="h2"
        className="text-center pb-16 lg:pb-24"
        disableMaxWidth
      >
        {t('help.title')}
      </Heading>

      <Container className="flex flex-col justify-center items-center">
        <LandingHelpStep
          item={step1}
          numberBadgeProps={{
            gradientColors:
              'from-landing-step-orange-light to-landing-step-orange',
          }}
          verticalLineProps={{
            className:
              'from-landing-step-orange-light md:to-landing-step-green-light xl:h-36 lg:h-36 md:h-44',
          }}
          bodyTextProps={{
            wrapperProps: {
              className:
                'md:absolute md:w-3/12 md:top-0 md:left-24 lg:left-40 xl:left-60',
            },
            headingProps: {
              className: 'md:text-right text-center',
              contentProps: {
                className:
                  'from-landing-step-orange to-landing-step-orange-light ',
              },
            },
            shortDescriptionProps: {
              className: 'md:text-right text-center mb-5',
            },
          }}
        />

        <LandingHelpStep
          item={step2}
          numberBadgeProps={{
            gradientColors:
              'from-landing-step-green-light to-landing-step-green',
          }}
          verticalLineProps={{
            className:
              'from-landing-step-green-light md:to-primary-light md:h-32 lg:h-20',
          }}
          bodyTextProps={{
            wrapperProps: {
              className:
                'md:absolute md:w-3/12 md:top-0 md:right-24 lg:right-40 xl:right-60',
            },
            headingProps: {
              className: 'md:text-left text-center',
              contentProps: {
                className:
                  'from-landing-step-green to-landing-step-green-light',
              },
            },
            shortDescriptionProps: {
              className: 'md:text-left text-center mb-5',
            },
          }}
        />

        <LandingHelpStep
          item={step3}
          numberBadgeProps={{
            gradientColors: 'from-primary-light to-primary-dark',
          }}
          verticalLineProps={{
            className: 'from-primary-light',
          }}
          bodyTextProps={{
            wrapperProps: {
              className: 'relative m-auto',
            },
            headingProps: {
              className: 'text-center',
              contentProps: {
                className: 'from-landing-step-navy-light to-primary-light',
              },
            },
          }}
        />

        <Box className="w-full flex flex-col items-center mb-8">
          <LoadableImage
            layout="responsive"
            src={lineGraphics}
            alt="Horizontal curly line graphics"
            loader={defaultImageLoader}
            wrapperClassName="hidden md:block md:w-8/12"
          />

          <Box className="flex flex-col md:flex-row md:justify-between md:w-9/12">
            <MobileVerticalBar />

            <LandingHelpStep
              item={step3a}
              showNumberBadge={false}
              showVerticalLine={false}
              bodyTextProps={{
                wrapperProps: {
                  className: 'relative md:w-1/2 md:mr-auto',
                },
                headingProps: {
                  className: 'text-center md:text-left',
                  contentProps: {
                    className: 'from-landing-step-navy-light to-primary-light',
                  },
                },
                shortDescriptionProps: {
                  className: 'text-center md:text-left',
                },
              }}
            />

            <MobileVerticalBar />

            <LandingHelpStep
              item={step3b}
              showNumberBadge={false}
              showVerticalLine={false}
              bodyTextProps={{
                wrapperProps: {
                  className: 'relative md:w-1/2 md:ml-auto',
                },
                headingProps: {
                  className: 'text-center md:text-right',
                  contentProps: {
                    className: 'to-primary-light from-landing-step-navy-light',
                  },
                },
                shortDescriptionProps: {
                  className: 'text-center md:text-right',
                },
              }}
            />
          </Box>
        </Box>

        <LandingHelpStep
          item={step4}
          numberBadgeProps={{
            gradientColors: 'from-landing-step-navy-light to-landing-step-navy',
          }}
          verticalLinePosition="top"
          verticalLineProps={{
            className:
              'from-transparent via-primary to-landing-step-navy-light md:h-10',
          }}
          bodyTextProps={{
            wrapperProps: {
              className:
                'md:absolute md:w-3/12 md:top-10 xl:right-60 lg:right-40 md:right-24',
            },
            headingProps: {
              className: 'md:text-left text-center',
              contentProps: {
                className: 'from-landing-step-navy to-landing-step-navy-light',
              },
            },
            shortDescriptionProps: {
              className: 'md:text-left text-center mb-5',
            },
          }}
        />

        <LandingHelpStep
          item={step5}
          numberBadgeProps={{
            gradientColors:
              'from-landing-step-violet-light to-landing-step-violet',
          }}
          verticalLinePosition="top"
          verticalLineProps={{
            className:
              'md:h-80 lg:h-56 from-transparent md:from-landing-step-navy-light to-landing-step-violet-light',
          }}
          bodyTextProps={{
            wrapperProps: {
              className: 'relative m-auto',
            },
            headingProps: {
              className: 'text-center',
              contentProps: {
                className:
                  'from-landing-step-violet to-landing-step-violet-light',
              },
            },
            shortDescriptionProps: {
              className: 'text-center',
            },
          }}
        />
      </Container>
    </Container>
  )
}

export default HowCanWeHelpSection
