import clsx from 'clsx'
import {ForwardedRef, forwardRef, HTMLProps} from 'react'

export interface CardProps extends HTMLProps<HTMLDivElement> {
  /**
   * Border radius of the component.
   *
   * @default 'rounded-lg'
   */
  borderRadius?: string
  /**
   * Determines which padding variant to use.
   *
   * @default 'default'
   */
  padding?: 'default' | 'none'
  /**
   * Default color of this component.
   *
   * @default 'light'
   */
  color?: 'light' | 'dark' | 'transparent'
  /**
   * Shadow settings of this component.
   *
   * @default 'sm'
   */
  shadow?: 'sm' | 'none'
}

export const Card = forwardRef(function Card(
  {
    className,
    children,
    onClick,
    onKeyDown,
    borderRadius = 'rounded-lg',
    padding = 'default',
    color = 'light',
    shadow = 'sm',
    ...props
  }: CardProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  return (
    <div
      className={clsx(
        color === 'light' && 'bg-white',
        color === 'dark' && 'bg-extra-dark',
        color === 'transparent' && 'bg-transparent',
        'w-full transition-shadow overflow-hidden',
        shadow === 'sm' && 'shadow-card',
        borderRadius,
        padding === 'default' && 'p-6',
        !!onClick && 'hover:shadow-card-hover',
        className,
      )}
      onClick={onClick}
      onKeyDown={onKeyDown}
      role={onClick && onKeyDown && 'button'}
      tabIndex={onClick && onKeyDown && 0}
      ref={ref}
      {...props}
    >
      {children}
    </div>
  )
})

export default Card
