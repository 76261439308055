import {HTMLProps} from 'react'
import clsx from 'clsx'
import {PartnerData} from '../../../lib/fetch-partner-data'
import LoadableImage from '../../atoms/loadable-image'

export interface PartnerLogosStripProps extends PartnerData {
  inView: boolean
  wrapperProps?: HTMLProps<Omit<HTMLDivElement, 'ref'>>
}

function PartnerLogosStrip({
  partnerLogos,
  inView,
  wrapperProps,
}: PartnerLogosStripProps) {
  const {className: wrapperClassName, ...restWrapperProps} = wrapperProps || {}

  return (
    <div
      className={clsx(
        'relative overflow-hidden px-0 md:px-4',
        'before:absolute before:z-40 before:top-0 before:bottom-0 before:left-0 before:h-full before:w-16 before:bg-gradient-to-r before:from-white',
        'after:absolute after:z-40 after:top-0 after:bottom-0 after:-right-1 after:h-full after:w-16 after:bg-gradient-to-l after:from-white',
        wrapperClassName,
      )}
      {...restWrapperProps}
    >
      <div
        className={clsx(
          'motion-safe:hidden motion-reduce:inline-flex',
          'items-center flex-wrap justify-center',
        )}
      >
        {partnerLogos.map(({image, alt, width, height}, index) => (
          <LoadableImage
            placeholderColor="transparent"
            src={image}
            alt={alt}
            width={width / 3.25}
            height={height / 3.25}
            layout="fixed"
            className="object-contain"
            borderRadius="rounded-none"
            wrapperClassName="m-4 md:m-6 lg:m-8 opacity-95"
            key={`${image}-${index}`}
            disableDefaultAnimation
          />
        ))}
      </div>
      <div
        className={clsx(
          'motion-reduce:hidden motion-safe:inline-flex',
          'items-center flex-nowrap',
          inView && 'animate-translate-horizontally transform-gpu',
        )}
      >
        {partnerLogos
          .concat(partnerLogos)
          .map(({image, alt, width, height}, index) => (
            <LoadableImage
              placeholderColor="transparent"
              src={image}
              alt={alt}
              width={width / 3.25}
              height={height / 3.25}
              layout="fixed"
              className="object-contain"
              borderRadius="rounded-none"
              wrapperClassName="mx-8 md:mx-10 lg:mx-12 opacity-95"
              key={`${image}-${index}`}
              disableDefaultAnimation
            />
          ))}
      </div>
    </div>
  )
}

export default PartnerLogosStrip
