import clsx from 'clsx'
import LoadableImage, {LoadableImageProps} from '../../atoms/loadable-image'

export interface AvatarProps
  extends Omit<LoadableImageProps, 'layout' | 'width' | 'height'> {
  /**
   * Size of the avatar in pixels.
   *
   * @default 175
   */
  size?: number
  /**
   * Border radius size.
   *
   * @default 'default'
   */
  borderRadius?: 'default' | 'small'
}

export function Avatar({
  size = 175,
  borderRadius = 'default',
  alt,
  className,
  ...props
}: AvatarProps) {
  return (
    <LoadableImage
      className={clsx('border-solid border-2 border-gray-200', className)}
      borderRadius={borderRadius === 'small' ? 'rounded-4xl' : 'rounded-5xl'}
      alt={alt}
      width={size}
      height={size}
      layout="fixed"
      disableDefaultAnimation
      quality={100}
      {...props}
    />
  )
}

export default Avatar
