import {Testimonial} from '../../../types'
import TestimonialCard from '../../molecules/testimonial-card'
import dynamic from 'next/dist/shared/lib/dynamic'

export type TestimonialSectionProps = {
  testimonials: Array<Testimonial>
}

const Slider = dynamic(() => import('../../molecules/slider'), {
  ssr: false,
  loading: () => <div className="w-full h-80" />,
})

export function TestimonialSection({testimonials}: TestimonialSectionProps) {
  return (
    <Slider loopback>
      {testimonials.map(({attestant, role, testimonial, photo}) => (
        <TestimonialCard
          key={attestant}
          attestant={attestant}
          role={role}
          testimonial={testimonial}
          photo={photo}
        />
      ))}
    </Slider>
  )
}

export default TestimonialSection
