
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import {MDXRemoteSerializeResult} from 'next-mdx-remote'
import {serialize} from 'next-mdx-remote/serialize'
import useTranslation from 'next-translate/useTranslation'
import {getPlaiceholder} from 'plaiceholder'
import Container from '../components/atoms/container'
import GridContainer from '../components/atoms/grid-container'
import Heading from '../components/atoms/heading'
import HighlightedSection from '../components/atoms/highlighted-section'
import Link from '../components/atoms/link'
import CallToAction from '../components/molecules/call-to-action'
import InfoBox from '../components/molecules/info-box'
import LinkButton from '../components/molecules/link-button'
import {PageCoverTitle} from '../components/molecules/page-cover-title'
import WhatWeCanOfferSection from '../components/templates/what-we-can-offer-section'
import HowCanWeHelpSection from '../components/templates/how-can-we-help-section/how-can-we-help-section'
import CaseStudySection from '../components/templates/case-study-section'
import HomePageCover from '../components/templates/home-page-cover'
import {TestimonialSection} from '../components/templates/testimonial-section'
import {FooterDataStateProps, FooterProvider} from '../contexts/footer-context'
import PageLayout from '../layouts/page-layout'
import fetchGeneralSettings from '../lib/fetch-general-settings'
import fetchHomeData, {HomeDataProps} from '../lib/fetch-home-data'
import fetchSocialLinks from '../lib/fetch-social-links'
import mapRawCaseStudyToCaseStudy from '../lib/map-raw-case-study-to-case-study'
import mapRawImageToImage from '../lib/map-raw-image-to-image'
import mapRawServiceToService from '../lib/map-raw-service-to-service'
import mapRawHelpStepsToHelpSteps from '../lib/map-raw-help-steps-to-help-steps'
import mapRawWhatWeOfferToWhatWeOffer from '../lib/map-raw-what-we-offer-to-what-we-offer'
import mapRawTestimonialToTestimonial from '../lib/map-raw-testimonial-to-testimonial'
import parseFrontMatter from '../lib/parse-front-matter'
import bishopSymbol from '../public/assets/bishop-logo-symbol.png'
import {CaseStudy} from '../types'

export default function Home({
  coverPhoto1,
  coverPhoto1Alt,
  coverPhoto2,
  coverPhoto2Alt,
  coverPhoto3,
  coverPhoto3Alt,
  coverDescription,
  metaDescription,
  previewImage,
  partnerLogos,
  services,
  caseStudies,
  testimonials,
  helpSectionSteps,
  whatWeOfferCover,
  whatWeOfferSectionItems,
  social,
  general,
}: HomeDataProps<MDXRemoteSerializeResult> & FooterDataStateProps) {
  const {t} = useTranslation('home')

  return (
    <FooterProvider value={{social, general}}>
      <PageLayout
        className="grid grid-flow-row gap-8 md:gap-16 lg:gap-24 pt-32 md:pt-36 lg:pt-32 pb-12 md:pb-24"
        disableDefaultPadding
        title={t('general.title')}
        titleTemplate="%s"
        description={metaDescription}
        openGraph={{images: [{url: previewImage || bishopSymbol.src}]}}
        prefixImageUrl={!previewImage}
      >
        <div>
          <HomePageCover
            title={
              <PageCoverTitle
                text={[
                  [
                    {text: t('cover.title.customized')},
                    {
                      text: t('cover.title.it-solutions'),
                      className: 'gradient-orange',
                    },
                  ],
                  [
                    {text: t('cover.title.for')},
                    {
                      text: t('cover.title.digital-health'),
                      className: 'gradient-blue',
                    },
                  ],
                ]}
              />
            }
            coverDescription={coverDescription}
            coverPhoto1={coverPhoto1}
            coverPhoto1Alt={coverPhoto1Alt}
            coverPhoto2={coverPhoto2}
            coverPhoto2Alt={coverPhoto2Alt}
            coverPhoto3={coverPhoto3}
            coverPhoto3Alt={coverPhoto3Alt}
            whatWeOfferCover={whatWeOfferCover}
            partnerLogos={partnerLogos}
          />

          {whatWeOfferSectionItems && (
            <HighlightedSection>
              <WhatWeCanOfferSection
                whatWeCanOfferItems={whatWeOfferSectionItems}
                title={t('what-we-offer.title')}
              />
            </HighlightedSection>
          )}

          <Container className="grid gap-4 md:gap-8 justify-items-center py-8 md:py-16 lg:py-24">
            {testimonials && <TestimonialSection testimonials={testimonials} />}
          </Container>

          {caseStudies && (
            <CaseStudySection
              title={t('case-studies.title')}
              caseStudies={caseStudies}
              showLinkIcon
            >
              <LinkButton className="mb-8 md:mb-16 lg:mb-24" href="/contact-us">
                {t('case-studies.link')}
              </LinkButton>
            </CaseStudySection>
          )}

          {services && (
            <HighlightedSection>
              <Heading variant="h2" className="text-center">
                {t('services.title')}
              </Heading>

              <GridContainer
                disableDefaultColumns
                className="grid-flow-row gap-y-8 gap-x-12 w-full"
              >
                {services.map(({icon, iconAlt, title, body, url}) => (
                  <InfoBox
                    icon={icon}
                    iconAlt={iconAlt}
                    title={title}
                    key={title}
                    iconOnTop
                    disableHeadingMaxWidth
                    hideUnderline
                    additionalChildren={
                      <Link
                        className="hover:underline text-lg justify-self-start"
                        active
                        shouldDisplayActiveUnderline={false}
                        href={url}
                        ariaLabel={t('common:general.learn-more-about', {
                          topic: title,
                        })}
                      >
                        {t('common:general.learn-more')}
                      </Link>
                    }
                  >
                    {body}
                  </InfoBox>
                ))}
              </GridContainer>
            </HighlightedSection>
          )}
        </div>

        {helpSectionSteps && <HowCanWeHelpSection items={helpSectionSteps} />}

        <Container>
          <CallToAction
            title={t('contact.title')}
            callToActionText={t('common:navigation.contact-us')}
            href="/contact-us"
          >
            {t('contact.body')}
          </CallToAction>
        </Container>
      </PageLayout>
    </FooterProvider>
  )
}

 async function _getStaticProps() {
  const {
    coverPhoto1,
    coverPhoto1Alt,
    coverPhoto2,
    coverPhoto2Alt,
    coverPhoto3,
    coverPhoto3Alt,
    coverDescription: rawCoverDescription,
    helpSectionSteps,
    whatWeOfferCover,
    whatWeOfferSectionItems,
    metaDescription,
    previewImage,
    partnerLogos,
    services,
    caseStudies,
    testimonials,
  } = fetchHomeData()

  const availableServices = await Promise.all(
    services.map(async service => {
      const data = await import(`../content/available-services/${service}.md`)

      return {...data, slug: service}
    }),
  )

  const mappedServices = availableServices
    .map(content => {
      return {
        ...parseFrontMatter<any>(content.default).attributes,
        slug: content.slug,
      }
    })
    .map(mapRawServiceToService)

  const offersOnCover = await Promise.all(
    whatWeOfferCover.map(async offer => {
      const data = await import(`../content/what-we-offer/${offer}.md`)
      return {...data, slug: offer}
    }),
  )

  const mappedOffersOnCover = offersOnCover
    .map(content => ({
      ...parseFrontMatter<any>(content.default).attributes,
      slug: content.slug,
    }))
    .map(mapRawWhatWeOfferToWhatWeOffer)

  const offersInWhatWeOfferSection = await Promise.all(
    whatWeOfferSectionItems.map(async offer => {
      const data = await import(`../content/what-we-offer/${offer}.md`)
      return {...data, slug: offer}
    }),
  )

  const availableCaseStudies = await Promise.all(
    caseStudies.map(
      caseStudy => import(`../content/case-studies/${caseStudy}.md`),
    ),
  )

  const mappedCaseStudies = availableCaseStudies
    .map(content => parseFrontMatter<CaseStudy>(content.default).attributes)
    .map(mapRawCaseStudyToCaseStudy)

  const availableTestimonials = await Promise.all(
    testimonials.map(
      testimonial => import(`../content/testimonials/${testimonial}.md`),
    ),
  )

  const mappedTestimonials = availableTestimonials.map(
    mapRawTestimonialToTestimonial,
  )

  const convertedPartnerLogos = partnerLogos
    ? await Promise.all(partnerLogos.map(({image}) => getPlaiceholder(image)))
    : []

  const mappedOffersInWhatWeOfferSection = offersInWhatWeOfferSection
    .map(content => ({
      ...parseFrontMatter<any>(content.default).attributes,
      slug: content.slug,
    }))
    .map(mapRawWhatWeOfferToWhatWeOffer)

  const mappedHelpSteps = helpSectionSteps.map(mapRawHelpStepsToHelpSteps)

  const coverDescription = await serialize(rawCoverDescription)

  return {
    props: {
      coverPhoto1,
      coverPhoto1Alt,
      coverPhoto2,
      coverPhoto2Alt,
      coverPhoto3,
      coverPhoto3Alt,
      coverDescription,
      metaDescription,
      previewImage,
      whatWeOfferCover: mappedOffersOnCover,
      whatWeOfferSectionItems: mappedOffersInWhatWeOfferSection,
      helpSectionSteps: mappedHelpSteps,
      partnerLogos: partnerLogos
        ? convertedPartnerLogos.map(mapRawImageToImage(partnerLogos))
        : [],
      services: mappedServices,
      caseStudies: mappedCaseStudies,
      testimonials: mappedTestimonials,
      general: fetchGeneralSettings(),
      social: fetchSocialLinks(),
    },
  }
}


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        let res = _getStaticProps(ctx)
// @ts-ignore
        if(typeof res.then === 'function') res = await res
// @ts-ignore
        return {
// @ts-ignore
          ...res,
// @ts-ignore
          props: {
// @ts-ignore
            ...(res.props || {}),
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/index',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  