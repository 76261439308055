import clsx from 'clsx'
import {HTMLProps} from 'react'
import {HelpStep} from '../../../types'
import Box from '../../atoms/box'
import Heading, {HeadingProps} from '../../atoms/heading'
import NumberBadge, {NumberBadgeProps} from '../../atoms/number-badge'
import Paragraph, {ParagraphProps} from '../../atoms/paragraph'

export interface HelpStepProps {
  /**
   * Help step item to display
   */
  item: HelpStep
  /**
   * Determines whether to show the number badge
   *
   * @default: true
   */
  showNumberBadge?: boolean
  /**
   * Props to customize the number badge
   */
  numberBadgeProps?: NumberBadgeProps
  /**
   * Determines whether to show the vertical line
   *
   * @default: true
   */
  showVerticalLine?: boolean
  /**
   * Determines the position of the vertical line relative to the number badge
   *
   * @default: 'bottom'
   */
  verticalLinePosition?: 'top' | 'bottom'
  /**
   * Props to customize the vertical line starting from the number badge
   */
  verticalLineProps?: Omit<HTMLProps<HTMLDivElement>, 'ref'>
  /**
   * Props to customize the body textbox and text
   */
  bodyTextProps?: {
    wrapperProps?: Omit<HTMLProps<HTMLDivElement>, 'ref'>
    headingProps?: HeadingProps & {
      contentProps?: Omit<HTMLProps<HTMLSpanElement>, 'ref'>
    }
    shortDescriptionProps?: ParagraphProps
  }
}

function LandingHelpStep({
  item,
  numberBadgeProps,
  showNumberBadge = true,
  showVerticalLine = true,
  verticalLinePosition = 'bottom',
  verticalLineProps,
  bodyTextProps,
}: HelpStepProps) {
  const {
    className: numberBadgeClassName,
    gradientColors,
    ...restNumberBadgeProps
  } = numberBadgeProps || {}

  const {className: verticalLineClassName, ...restVerticalLineProps} =
    verticalLineProps || {}

  const {wrapperProps, headingProps, shortDescriptionProps} =
    bodyTextProps || {}

  const {className: textboxWrapperClassName, ...restTextboxWrapperProps} =
    wrapperProps || {}

  const {
    contentProps,
    className: headingClassName,
    ...restHeadingProps
  } = headingProps || {}

  const {className: contentClassName, ...restContentProps} = contentProps || {}

  const VerticalLine = () => (
    <Box
      className={clsx(
        'w-0.5 bg-gradient-to-b m-auto h-7',
        verticalLineClassName,
      )}
      {...restVerticalLineProps}
    />
  )

  return (
    <Box className="relative w-full">
      {showVerticalLine && verticalLinePosition === 'top' && <VerticalLine />}

      {showNumberBadge && item.stepNumber && (
        <NumberBadge
          gradientColors={gradientColors}
          className={clsx('m-auto', numberBadgeClassName)}
          {...restNumberBadgeProps}
        >
          {item.stepNumber}
        </NumberBadge>
      )}

      {showVerticalLine && verticalLinePosition === 'bottom' && (
        <VerticalLine />
      )}

      <Box
        className={clsx('relative w-full', textboxWrapperClassName)}
        {...restTextboxWrapperProps}
      >
        <Heading
          variant="h4"
          className={clsx('py-2', headingClassName)}
          disableMaxWidth
          {...restHeadingProps}
        >
          <Box
            component="span"
            className={clsx(
              'bg-clip-text text-transparent bg-gradient-to-r',
              contentClassName,
            )}
            {...restContentProps}
          >
            {item.title}
          </Box>
        </Heading>
        {item.body && (
          <Paragraph fontSize="text-base" {...shortDescriptionProps}>
            {item.body}
          </Paragraph>
        )}
      </Box>
    </Box>
  )
}

export default LandingHelpStep
