import clsx from 'clsx'
import {Box} from '../../atoms/box'
import {Heading} from '../../atoms/heading'
import {Paragraph} from '../../atoms/paragraph'

export type PageCoverTitleProps = {
  text: Array<Array<PageCoverTitleText>>
  fontSize?: string
  className?: string
}

export type PageCoverTitleText = {
  text: string
  className?: string
}

export function PageCoverTitle({
  text,
  fontSize = 'text-4xl',
  className,
}: PageCoverTitleProps) {
  return (
    <Heading
      disableMaxWidth
      className={clsx(
        'text-white font-black text-center lg:text-left',
        className,
      )}
    >
      {text.map((line, i) => (
        <Paragraph key={`title_line_${i}`} fontSize={fontSize}>
          {line.map(({text, className = ''}) => (
            <Box key={text} component="span" className={className}>
              {text}
            </Box>
          ))}
        </Paragraph>
      ))}
    </Heading>
  )
}

export default PageCoverTitle
