import clsx from 'clsx'
import Image from 'next/image'
import {DataWithIcon, DataWithTitleAndBody} from '../../../types/common'
import Box from '../../atoms/box'
import Container from '../../atoms/container'
import Heading, {HeadingProps} from '../../atoms/heading'
import Paragraph, {ParagraphProps} from '../../atoms/paragraph'

type WhatWeOfferWidgetProps = {
  item: DataWithIcon & DataWithTitleAndBody
  titleProps?: HeadingProps
  bodyProps?: ParagraphProps
}

function WhatWeOfferWidget({
  item,
  titleProps,
  bodyProps,
}: WhatWeOfferWidgetProps) {
  const {icon, iconAlt, title, body} = item
  const {className: titleClassName, ...restTitleProps} = titleProps || {}
  const {className: bodyClassName, ...restBodyProps} = bodyProps || {}

  return (
    <Container disableDefaultPadding>
      <Box className="w-12 h-12">
        <Image
          src={icon}
          alt={iconAlt}
          layout="responsive"
          sizes="128px"
          width={50}
          height={50}
          quality={100}
        />
      </Box>
      <Heading
        variant="h3"
        className={clsx('pt-6 pb-1', titleClassName)}
        {...restTitleProps}
      >
        {title}
      </Heading>
      <Paragraph className={bodyClassName} {...restBodyProps}>
        {body}
      </Paragraph>
    </Container>
  )
}

export default WhatWeOfferWidget
