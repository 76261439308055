import clsx from 'clsx'
import {HTMLProps} from 'react'
import Paragraph, {ParagraphProps} from '../../atoms/paragraph'

export interface TestimonialProps extends HTMLProps<HTMLDivElement> {
  attestant: string
  role: string
  avatar?: React.ReactNode
  paragraphProps?: ParagraphProps
}

export function Testimonial({
  avatar,
  attestant,
  role,
  children,
  className,
  paragraphProps,
  ...props
}: TestimonialProps) {
  return (
    <div {...props} className={clsx('grid gap-4', className)}>
      <Paragraph {...paragraphProps}>{children}</Paragraph>

      <div className="grid grid-flow-col gap-4 items-center place-content-start text-lg">
        {avatar}
        <div className="grid grid-flow-row">
          <strong>{attestant}</strong> <span>{role}</span>
        </div>
      </div>
    </div>
  )
}

export default Testimonial
