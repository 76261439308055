import {Testimonial as TestimonialType} from '../../../types'
import {Card} from '../../atoms/card'
import {Avatar} from '../avatar'
import Testimonial from '../testimonial/testimonial'

export function PageCoverTitle({
  attestant,
  role,
  testimonial,
  photo,
}: TestimonialType) {
  return (
    <Card
      shadow="none"
      borderRadius="rounded-2xl md:rounded-3xl"
      className="text-left"
      color="light"
      key={`${attestant}-${role}`}
    >
      <Testimonial
        avatar={
          photo && (
            <Avatar
              src={photo}
              alt={attestant}
              size={75}
              borderRadius="small"
              disableDefaultAnimation
            />
          )
        }
        attestant={attestant}
        role={role}
        paragraphProps={{
          className: 'mb-2',
          fontSize: 'text-lg md:text-xl',
        }}
      >
        {testimonial}
      </Testimonial>
    </Card>
  )
}

export default PageCoverTitle
