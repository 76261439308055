import {DataWithIcon, DataWithTitleAndBody} from '../../../types'
import Container from '../../atoms/container'
import GridContainer from '../../atoms/grid-container'
import GridItem from '../../atoms/grid-item'
import Heading from '../../atoms/heading'
import WhatWeOfferWidget from '../../molecules/what-we-offer-widget/what-we-offer-widget'

export interface WhatWeCanOfferSectionProps {
  /**
   * List of items to be displayed here.
   */
  whatWeCanOfferItems: Array<DataWithIcon & DataWithTitleAndBody>
  /**
   * Title of this section. Pass a translated value.
   */
  title: string
}

function WhatWeCanOfferSection({
  whatWeCanOfferItems,
  title,
}: WhatWeCanOfferSectionProps) {
  return (
    <Container
      disableDefaultPadding
      className="grid grid-flow-row gap-4 place-content-start"
    >
      <Heading
        variant="h2"
        className="text-center pb-0 md:pb-12"
        disableMaxWidth
      >
        {title}
      </Heading>

      <GridContainer
        disableDefaultColumns
        className="px-0 lg:grid-cols-3 lg:grid-rows-2 md:grid-cols-2 md:grid-rows-3 sm:grid-cols-1 sm:grid-rows-6 gap-8 md:gap-0 md:gap-y-8"
      >
        {whatWeCanOfferItems.length > 0 &&
          whatWeCanOfferItems.map(item => (
            <GridItem key={item.title}>
              <WhatWeOfferWidget
                item={item}
                titleProps={{className: 'text-typography'}}
              />
            </GridItem>
          ))}
      </GridContainer>
    </Container>
  )
}

export default WhatWeCanOfferSection
