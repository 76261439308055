import clsx from 'clsx'
import type {MDXRemoteSerializeResult} from 'next-mdx-remote'
import useTranslation from 'next-translate/useTranslation'
import {useState} from 'react'
import {useInView} from 'react-intersection-observer'
import type {HomeDataProps} from '../../../lib/fetch-home-data'
import GridContainer from '../../atoms/grid-container'
import GridItem from '../../atoms/grid-item'
import LoadableImage from '../../atoms/loadable-image'
import PageCover, {PageCoverProps} from '../../organisms/page-cover'
import PartnerLogosStrip from '../../organisms/partner-logos-strip/partner-logos-strip'
import {InfoBox} from '../../molecules/info-box'

export type HomePageCoverProps = Pick<
  HomeDataProps<MDXRemoteSerializeResult>,
  | 'coverPhoto1'
  | 'coverPhoto1Alt'
  | 'coverPhoto2'
  | 'coverPhoto2Alt'
  | 'coverPhoto3'
  | 'coverPhoto3Alt'
  | 'coverDescription'
  | 'whatWeOfferCover'
  | 'partnerLogos'
> &
  Pick<PageCoverProps, 'title'>

export function HomePageCover({
  coverPhoto1,
  coverPhoto1Alt,
  coverPhoto2,
  coverPhoto2Alt,
  coverPhoto3,
  coverPhoto3Alt,
  title,
  coverDescription,
  whatWeOfferCover,
  partnerLogos,
}: HomePageCoverProps) {
  const [frontImageLoaded, setFrontImageLoaded] = useState(false)
  const [middleImageLoaded, setMiddleImageLoaded] = useState(false)
  const [backImageLoaded, setBackImageLoaded] = useState(false)
  const {t} = useTranslation('home')
  const {inView, ref} = useInView({triggerOnce: true})

  return (
    <PageCover
      className="min-h-210 motion-reduce:mt-20 mb-16"
      ref={ref}
      title={title}
      description={coverDescription}
      media={
        <div className="relative h-80 md:h-96">
          <div className="absolute -top-14 md:-top-10 bottom-0 left-0 right-0 blur-2xl z-0 motion-safe:animate-fade-in motion-reduce:opacity-100 motion-reduce:scale-110">
            <div
              className={clsx(
                'absolute top-32 -left-24 right-0 mx-auto rounded-full w-32 h-32 sm:w-40 sm:h-40 lg:w-52 lg:h-52 motion-reduce:scale-150 bg-gradient-to-br from-inverse to-inverse-light opacity-50',
                inView &&
                  'motion-safe:animate-skew-and-rotate-md-delay motion-safe:transform-gpu ',
              )}
            />
            <div
              className={clsx(
                'absolute top-32 left-0 right-0 mx-auto rounded-full w-32 h-32 sm:w-40 sm:h-40 lg:w-52 lg:h-52 motion-reduce:scale-150 bg-gradient-to-br from-red-400 to-pink-400 opacity-50',
                inView &&
                  'motion-safe:animate-reverse-skew-and-rotate motion-safe:transform-gpu',
              )}
            />
            <div
              className={clsx(
                'absolute top-32 left-24 right-0 mx-auto rounded-full w-32 h-32 sm:w-40 sm:h-40 lg:w-52 lg:h-52 motion-reduce:scale-150 bg-gradient-to-br from-purple-400 to-blue-400 opacity-50',
                inView &&
                  'motion-safe:animate-skew-and-rotate motion-safe:transform-gpu',
              )}
            />
          </div>

          <div className="relative top-8 motion-safe:animate-fade-in max-w-xxs motion-safe:transition-all sm:max-w-md lg:max-w-lg w-full h-full mx-auto">
            {frontImageLoaded && (
              <div
                className={clsx(
                  'absolute left-0 right-0 mx-auto max-w-xs md:max-w-sm w-full z-0',
                  middleImageLoaded &&
                    backImageLoaded &&
                    'motion-safe:animate-translate-top-right motion-reduce:-translate-y-6 motion-reduce:translate-x-10',
                )}
              >
                <LoadableImage
                  src={coverPhoto1}
                  alt={coverPhoto1Alt}
                  priority
                  quality={85}
                  width={450}
                  height={300}
                  className="object-cover"
                  layout="responsive"
                  placeholderColor="transparent"
                  sizes="(max-width: 640px) 256px, 384px"
                  disableDefaultAnimation
                  onLoadingComplete={() => setBackImageLoaded(true)}
                />
              </div>
            )}

            {frontImageLoaded && (
              <div className="absolute left-0 right-0 mx-auto max-w-xs md:max-w-sm w-full z-10">
                <LoadableImage
                  src={coverPhoto2}
                  alt={coverPhoto2Alt}
                  priority
                  quality={85}
                  width={450}
                  height={300}
                  className="object-cover"
                  layout="responsive"
                  placeholderColor="transparent"
                  sizes="(max-width: 640px) 256px, 384px"
                  disableDefaultAnimation
                  onLoadingComplete={() => setMiddleImageLoaded(true)}
                />
              </div>
            )}

            <div
              className={clsx(
                'absolute left-0 right-0 mx-auto max-w-xs md:max-w-sm w-full z-20',
                frontImageLoaded &&
                  middleImageLoaded &&
                  backImageLoaded &&
                  'motion-safe:animate-translate-bottom-left motion-reduce:translate-y-6 motion-reduce:-translate-x-10',
              )}
            >
              <LoadableImage
                src={coverPhoto3}
                alt={coverPhoto3Alt}
                priority
                quality={85}
                layout="responsive"
                width={450}
                height={300}
                className="object-cover"
                placeholderColor="transparent"
                sizes="(max-width: 640px) 256px, 384px"
                disableDefaultAnimation
                onLoadingComplete={() => setFrontImageLoaded(true)}
              />
            </div>
          </div>
        </div>
      }
    >
      {whatWeOfferCover && (
        <GridContainer
          disableDefaultColumns
          className="lg:grid-cols-4 lg:grid-rows-1 md:grid-cols-2 md:grid-rows-2 sm:grid-cols-1 sm:grid-rows-4 gap-12 md:gap-0 md:gap-y-8 pb-8"
        >
          {whatWeOfferCover.length > 0 &&
            whatWeOfferCover.map(({title, icon, iconAlt, body}) => (
              <GridItem key={title}>
                <InfoBox
                  key={title}
                  title={title}
                  icon={icon}
                  iconAlt={iconAlt}
                  hideUnderline
                  iconOnTop
                  className="grid-flow-col"
                >
                  {body}
                </InfoBox>
              </GridItem>
            ))}
        </GridContainer>
      )}
      {partnerLogos && (
        <PartnerLogosStrip inView={inView} partnerLogos={partnerLogos} />
      )}
    </PageCover>
  )
}

export default HomePageCover
